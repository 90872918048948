
@import '~antd/dist/antd.css';

html, body {
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#menu {
  display: none;
  position: absolute;
  width: 150px;
  background-color: white;
  box-shadow: 0 0 5px grey;
  border-radius: 3px;
  z-index: 9000;
}

#menu button {
  width: 100%;
  background-color: white;
  border: none;
  // margin: 0;
  // padding: 10px;
}

#menu button:hover {
  background-color: lightgray;
}

.side-panel {
  hr {
    margin: 20px -15px;
  }

  h3, h4 {
    font-weight: 900;
  }

  > .ant-row {
    margin: 0 !important;
  }
}

.custom-groups {
  table {
    border: 0;
    border-collapse: collapse;
    table-layout: fixed;
  }

  th, td {
    padding: 5px 0;
    font-weight: bold;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.custom-distances {
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  table, th, td {
    border: 1px solid black;
    text-align: center;
  }
}

input {
  width: 100%;
}

a {
  text-decoration: none;
  color: blue;
}

.option-colour, .select-colour {
  padding: 0
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 2px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding: 0;
}

.ant-upload.ant-upload-select {
  width: 100%;
}

.ant-list {
  // font-size: 11px;
  line-height: 0;
  min-width: 100px;
  height: 190px;
  // overflow: scroll;

  .ant-spin-container {
    overflow-y: scroll;
    height: 170px;
  }
}

.ant-list-bordered.ant-list-sm .ant-list-header, .ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 0;
}

.ant-list-bordered.ant-list-sm .ant-list-item {
  padding: 0 5px;
  // width: 100%;
  height: 16px;
  display: block;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
}

.ant-card-bordered {
  overflow-y: scroll;
  height: 284px;
}

// .ant-empty-normal {
//   margin: 0;

//   .ant-empty-image {
//     height: 20px;
//   }
// }

.ant-drawer-body {
  background: lightsteelblue;
  padding: 0;
}

.ant-input-number-lg {
  border-radius: 16px;
  overflow: hidden;
  display: flex;
}

.ant-row {
  justify-content: space-between;
}

.calibration-title {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre;
}

/* Basic button styling */

.pulsing {
  // width: 220px;
  // text-align: center;
  // white-space: nowrap;
  // display: block;
  // margin: 50px auto;
  // padding: 10px;
  box-shadow: 0 0 0 0 #1890ff;
  // border-radius: 10px;
  // background-color: #FF0000;
  -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  // font-size: 22px;
  // font-weight: normal;
  // font-family: sans-serif;
  // text-decoration: none !important;
  // color: #ffffff;
  transition: all 300ms ease-in-out;
}


/* Comment-out to have the button continue to pulse on mouseover */

a.pulsing:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  animation: none;
  color: #ffffff;
}


/* Animation */

@-webkit-keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@-moz-keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@-ms-keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}
